<template>
  <table-parent
    tableStyle="min-height: 0px; border-radius: 0px; margin-right: 0px; box-shadow: none;"
    apiModuleName="VERBAL_PROCESS"
    :reloadCount="reloadTable"
    :tableHead="tableHead"
    :prepareFn="prepareData"
    :apiModule="tableModule"
    :tableMinimizeHead="true"
    :tableSpread="true"
    :apiModuleOptions="{ hideControl: true }"
    @totalRecords="(x) => (totalRecords = x)"
  >
  </table-parent>
</template>

<script>
import { VERBAL_PROCESS } from "../api.js";
import TableParent from "@/components/TableParent";

export default {
  props: {
    verbalProcessId: {
      type: Number,
      required: true,
    },
  },

  components: {
    TableParent,
  },

  data() {
    return {
      centralization: null,
      tableHead: [
        {
          title: "Furnizor",
        },
        {
          title: "Valoare totală fără TVA",
        },
        {
          title: "Cotă TVA",
        },
        {
          title: "Valoare totală cu TVA",
        },
      ],
      tableModule: (x, y) => VERBAL_PROCESS.getProviders(this.verbalProcessId),
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    reload() {
      this.reloadTable++;
    },
    prepareData(row) {
      const preparation = [
        row.institutionProvider?.name || "",
        this.prepareLocaleNumber(row.totalValueWithoutTva) || "",
        this.prepareLocaleNumber(row.tvaValue) || "",
        this.prepareLocaleNumber(row.totalValueWithTva) || "",
      ];

      const x = this.PERMISIONS;

      const prepareBtn = [];

      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return Object.assign(preparation, {
        _Data: row,
      });
    },
  },

  created() {},
};
</script>
<style>
.custom-size {
  max-width: 1200px !important;
  max-height: 100% !important;
}
</style>
